<template>
    <div class="col-12">
        <div class="mb-3">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th colspan="2" class="text-center">BASAL</th>
                        <th colspan="2" class="text-center">15 MIN</th>
                        <th colspan="2" class="text-center">30 MIN</th>
                        <th colspan="2" class="text-center">45 MIN</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th class="text-center">PIO (mmHg)</th>
                        <th class="text-center">EC (mm)</th>
                        <th class="text-center">PIO</th>
                        <th class="text-center">EC</th>
                        <th class="text-center">PIO</th>
                        <th class="text-center">EC</th>
                        <th class="text-center">PIO</th>
                        <th class="text-center">EC</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">OD</th>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_basal_pio"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_basal_ec"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_uno_pio"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_uno_ec"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_dos_pio"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_dos_ec"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_tres_pio"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.od_tres_ec"></td>
                    </tr>
                    <tr>
                        <th scope="row">OI</th>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_basal_pio"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_basal_ec"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_uno_pio"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_uno_ec"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_dos_pio"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_dos_ec"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_tres_pio"></td>
                        <td><input type="text" class="form-control-plaintext" v-model="model.oi_tres_ec"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-12">
        <div class="mb-3">
            <label class="col-form-label">Comentario</label>
            <textarea class="form-control" :disabled="disabled" rows="4" v-model="model.comentarios"></textarea>
        </div>
    </div>
</template>
<script setup>
import { defineProps, defineModel } from 'vue';

const model = defineModel();
defineProps({
    disabled: { type: Boolean, default: false },
});
</script>

<style></style>
